const idPortalApi = "bc8c9db563a0a2a730a5494325bf41fba1efe15b";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmpassagemfranca.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmpassagemfranca.ma.gov.br",
  NomePortal: "Passagem Franca",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/YBz9mdovkBFLQgah9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15866.501246911184!2d-43.7892258!3d-6.1808787!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7842a8a2e024497%3A0x1b1740d2d915b35a!2sPassagem%20Franca%2C%20MA%2C%2065680-000!5e0!3m2!1spt-BR!2sbr!4v1721854341777!5m2!1spt-BR!2sbr",
};
